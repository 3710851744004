<template>
  <div class="card h-100">
    <div
        class="card-header calendar-day-header text-light font-weight-bold"
        :class="'bg-' + dayClass"
    >
      {{dateString(day.date,'M/d eeeeee')}}
      <span class="badge badge-pill badge-light float-right">{{
        dayType
      }}</span>
    </div>
    <div
        v-if="note || fee"
        class="list-group list-group-flush"
    >
      <div
          v-if="fee"
          class="list-group-item p-2 m-0"
          :class="'bg-danger-light'"
      >
        <strong>Extra Fee:</strong> {{ currency(fee) }}
      </div>
      <div
          v-if="note"
          class="list-group-item p-1 m-0"
          :class="'bg-' + dayClass + '-light'"
          v-html="note"
      />
    </div>
    <div class="card-body p-2" :class="'bg-' + dayClass + '-light'">
      <div
          v-if="signupClosed && dayType !== 'No Programing'"
          class="text-danger"
      >
        {{ signupClosedReason }}
      </div>
      <div
          v-else-if="blockEnrollmentWithUnpaid && dayType !== 'No Programing'"
          class="bg-danger-light m-1 p-0"
      >
        <p class="text-danger">
          Signing up is blocked due to unpaid
          registrations
        </p>
      </div>
      <template v-else>
        <span v-if="dayType === 'Full Day'" class="card-subtitle text-full_day">
          Select pick a drop off <strong>and</strong> pickup time for
          {{ dayType }}.
        </span>
        <span
            v-else-if="'No Programing' === dayType"
            class="card-subtitle text-no_programing"
        >
          There is no programing on this day.
        </span>
        <span :class="'text-' + dayClass" class="card-subtitle" v-else>
          Select morning and/or afternoon options for
          {{ dayType === 'Half Day'?'Half Days':dayType + ' days' }}.
        </span>
      </template>
    </div>
    <template v-if="calendar.only_in_course && dayType !== 'No Programing'">
      <app-enrolled-overlap-summary
          :students="students"
          :enrolled="courses"
          :day="day"
      />
    </template>
    <template v-if="!signupClosed">
      <day-enroll
          class="list-group list-group-flush"
          @added-to-cart="addedToCart"
          @enrolled-course="enrolledCourse"
          :templates="templates"
          :day="day"
          :calendar="calendar"
          :students="students"
          :enrolled="enrolled"
          :overlap-courses="courses"
          :signup-closed="signupClosed"
          :can-use-credit-for-enrollment="canUseCreditForEnrollment"

      />
    </template>
    <app-enrolled-day-summary
        v-if="signupClosed"
        :courses="coursesForDay"
        :students="students"
        class="list-group list-group-flush"
    />
  </div>
</template>

<script>
import Moment from 'moment'
import { extendMoment } from 'moment-range'
import { isEmpty } from 'lodash/core'
import { mapGetters } from 'vuex'
import EnrollButtons from '@/components/shared/EnrollButtons'
import EnrolledOverlapSummary from '@/components/calendars_sessions/calendars/EnrolledOverlapSummary'
import EnrolledDaySummary from '@/components/calendars_sessions/calendars/EnrolledDaySummary'
import { SIZE_WARNING, SIZE_ALERT } from '@/constants/application'
import { dayOfWeekInBitmask } from '@/utilities/bitmaskMaps'
import DayEnroll from '@/components/calendars_sessions/calendars/days/DayEnroll'
import { dateString } from '@/utilities/dateFormatters'
import { currency } from '@/utilities/stringFormatters'
import DayEnrollButtons from '@/components/calendars_sessions/calendars/days/DayEnrollButtons.vue'

const moment = extendMoment(Moment)

export default {
  name: 'Day',
  props: {
    day: {
      type: Object,
      required: true
    },
    calendar: {
      type: Object,
      required: true
    },
    students: {
      type: [Object, Array],
      default: function () {
        return {}
      }
    },
    enrolled: {
      type: Object,
      required: false
    },
    canUseCreditForEnrollment: {
      type: Boolean,
      required: true
    }
  },
  components: {
    DayEnrollButtons,
    DayEnroll,
    appEnrollButtons: EnrollButtons,
    appEnrolledOverlapSummary: EnrolledOverlapSummary,
    appEnrolledDaySummary: EnrolledDaySummary
  },
  computed: {
    ...mapGetters({
      blockEnrollmentWithUnpaid: 'blockEnrollmentWithUnpaid',
      registration: 'registration',
      calendars: 'calendars',
      program: 'program',
      loggedIn: 'loggedIn',
      templateByStart: 'templateByStart',
      templateByEnd: 'templateByEnd',
      specialDayTemplates: 'specialDayTemplates',
      isManager: 'isProgramManager'
    }),
    templates: function () {
      const day = this.day
      const specialDay = day.special_day
      return this.specialDayTemplates(specialDay)
    },
    dayOfWeek: function () {
      const dayOfWeek = moment(this.day.date).day()
      return dayOfWeek
    },
    dayType: function () {
      if (typeof this.day.special_day === 'undefined') {
        return 'Normal'
      } else {
        return this.day.special_day.name
      }
    },
    dayClass: function () {
      if (typeof this.day.special_day === 'undefined') {
        return 'normal'
      } else {
        return this.day.special_day.type
      }
    },
    signupClosedReason: function () {
      if (this.signupClosed) {
        if (this.registration.closed) {
          return 'Registration Closed'
        }

        const signupStartDate = moment(this.calendar.signup_start_date)
        const signupEndDate = moment(this.calendar.signup_end_date)
        const date = moment(this.day.date)
        const now = moment()
        if (signupStartDate.isAfter(date) && date.isAfter(now)) {
          return 'Scheduling will open ' + signupStartDate.format('M/d')
        }
        if (signupStartDate.isAfter(date) && !date.isAfter(now)) {
          return 'Scheduling over.'
        }
        if (signupEndDate.isBefore(date)) {
          return 'Scheduling unavailable'
        }
        return 'Scheduling Closed'
      }
      return ''
    },
    signupClosed: function () {
      if (!this.isManager) {
        if (this.registration.closed) {
          return true
        }
        if (this.calendar.scheduling_open) {
          const date = moment(this.day.date)
          const signupStartDate = moment(this.calendar.signup_start_date)
          const signupEndDate = moment(this.calendar.signup_end_date)
          return signupStartDate.isAfter(date) || signupEndDate.isBefore(date)
        }
        return true
      } else {
        return false
      }
    },
    overlapCourses () {
      const calendar = this.calendar
      if (calendar.credit_overlap) {
        return calendar.credit_overlap_students
      }
      return []
    },
    coursesForDay () {
      const courses = this.calendar.courses
      if (isEmpty(courses)) {
        return []
      }
      const day = moment(this.day.date)
      const result = courses.filter(function (course) {
        const courseStart = moment(course.startDate)
        if (courseStart.isSame(day, 'day')) {
          return true
        } else {
          return false
        }
      })
      return result
    },
    courses () {
      const courses = {}
      const overlap = this.overlapCourses
      const day = moment(this.day.date)
      for (const student of this.students) {
        let studentCourses = []
        if (!isEmpty(overlap) && typeof overlap[student.id] !== 'undefined') {
          for (const course of overlap[student.id]) {
            const enrolledCourse = course.enrolled_course
            const courseStart = moment(course.startDate)
            const courseEnd = moment(course.endDate)
            if (day.isBetween(courseStart, courseEnd, 'day', '[]')) {
              // check the day of week.
              const enrolledDayBit = enrolledCourse.day_bit
              const dayBit = course.day_bit
              let found = dayOfWeekInBitmask(this.dayOfWeek, enrolledDayBit)
              if (!found) {
                found = dayOfWeekInBitmask(this.dayOfWeek, dayBit)
              }
              if (found) {
                studentCourses = studentCourses.concat(course)
              }
            }
          }
        }
        courses[student.id] = studentCourses
      }
      return courses
    },
    note () {
      if (
        typeof this.day.special_day !== 'undefined' &&
          this.day.special_day.note !== ''
      ) {
        return this.day.special_day.note
      }
      return ''
    },
    fee () {
      if (
        typeof this.day.special_day !== 'undefined' &&
          this.day.special_day.fee !== ''
      ) {
        return this.day.special_day.fee
      }
      return 0
    }
  },
  methods: {
    dateString,
    currency,
    addedToCart (payload) {
      console.log('Day.addedToCart new', payload)
      this.$emit('added-to-cart', payload)
    },
    enrolledCourse (payload) {
      console.log('Day.enrolledCourse', payload)
      this.$emit('enrolled-course', payload)
    },
    showTemplate (template) {
      if (this.calendar.credit_overlap) {
        const enrolled = this.courses
        const start = moment(template.startTime)
        const end = moment(template.endTime)
        if (typeof enrolled !== 'undefined' && !isEmpty(enrolled)) {
          let show = false
          for (const studentId in enrolled) {
            if (Object.prototype.hasOwnProperty.call(enrolled, studentId)) {
              const courses = enrolled[studentId]
              if (typeof courses !== 'undefined' && courses.length > 0) {
                courses.some(course => {
                  // const day = this.day.date;
                  const startTime = moment(course.startTime)
                  const endTime = moment(course.endTime)
                  const range = moment.range(startTime, endTime)
                  show =
                      show ||
                      ((range.contains(start) || range.contains(end)) &&
                          !(range.contains(start) && range.contains(end)))
                  return show
                })
              }
            }
          }
          return show
        }
      }
      return true
    },
    courseForTemplate (template) {
      if (!isEmpty(this.day.course)) {
        const courses = this.day.course
        if (!isEmpty(courses[template.id])) {
          return courses[template.id]
        }
      }
      return null
    },
    spaceWarning: function (template) {
      const course = this.courseForTemplate(template)
      if (
        !isEmpty(course) &&
          course.space > SIZE_ALERT &&
          course.space <= SIZE_WARNING
      ) {
        return true
      } else {
        return false
      }
    },
    spaceDanger: function (template) {
      const course = this.courseForTemplate(template)
      if (!isEmpty(course) && course.space <= SIZE_ALERT) {
        return true
      } else {
        return false
      }
    },
    spaceFull: function (template) {
      const course = this.courseForTemplate(template)
      if (!isEmpty(course) && course.space <= 0) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style scoped></style>
